/* eslint-disable react/jsx-no-target-blank */

/**
 * About component
 * @returns {JSX.Element} About component
 */
export default function About() {
	/** BAR resources, in order that it will display in about section */
	const barResources = {
		"Arabidopsis Citation Network Viewer": {
			link: "//bar.utoronto.ca/50YearsOfArabidopsis/",
		},
		"Arabidopsis Resources": {
			link: "https://conf.arabidopsis.org/display/COM/Resources",
			newTab: true,
		},
		"Provart Lab": {
			link: "https://provart.csb.utoronto.ca/",
			newTab: true,
		},
		"Project Students": {
			link: "https://provartlabundergrads.csb.utoronto.ca/",
			newTab: true,
		},
		"BAR Instructions": {
			link: "//bar.utoronto.ca/affydb/BAR_instructions.html",
		},
		"BAR Video Walkthrough": {
			link: "https://vimeo.com/271332184",
			newTab: true,
		},
		"ePlant Video Walkthrough": {
			link: "https://vimeo.com/273931307",
			newTab: true,
		},
	};

	/** Version data, in oder that it will appear in about section */
	const versionData = [
		"ThaleMine v5.1.0-20230710",
		"Arabidopsis Annotations from TAIR, Araport11_functional_descriptions_20220630.txt.gz, was merged with Gene Aliases from TAIR, gene_aliases_20220630.txt.gz (same as ThaleMine).",
		"Arabidopsis data from NCBI are updated using NCBI's gene_info file (Downloaded: 2023-07-06 - same as ThaleMine)",
		"Arabidopsis data from NCBI are updated using NCBI's gene2accession.gz (Downloaded: 2023-10-05)",
		"Publications and GeneRIFs for ePlants Arabidopsis, Maize, Medicago, Soybean, and Sunflower were downloaded from NCBI [most recent version as of April, 2020]",
		"The MapMan data used by Classification SuperViewer is updated to Ath_AGI_LOCUS_TAIR10_Aug2012.txt. [most recent version as of 4 June 2013].",
		"Promomer data sets are updated to TAIR10. [most recent version as of 4 June 2013].",
		"Arabidopsis Annotation from TAIR, called TAIR10_pep_20101209, which is merged with Gene Aliases from TAIR, called gene_aliases_20190402.txt.gz. [most recent public versions as of April, 2020]",
		"Arabidopsis GO Classification from TAIR, ATH_GO_GOSLIM.txt.gz. (Version: 2022-06-30).",
		"Medicago GO Classification from GO,UniProt goa_uniprot_all.gaf.gz and agriGO item2term_61, and TAIR ATH_GO_GOSLIM.txt.gz. [most recent public version as of April, 2020].",
		"Affymetrix probe set identifier to AGI number lookup table from TAIR dated 20 December 2010. It was called affy_ATH1_array_elements-2010-12-20.txt. [most recent version as of 21 September 2012]",
		"Predicted protein-protein interactions from Dr. Matt Geisler and colleagues at Southern Illinois University. Interactome 2.0 [most recent version as of 10 February 2009]",
		"70,944 predicted protein-protein interactions plus 62,626 experimentally determined PPIs and ~2.8 million protein-DNA interactions from hundreds of publications.",
		"Documented and predicted subcellular localizations from Dr. A. Harvey Millar and colleagues at the University of Western Australia. SUBA4 [updated 2018]",
		"Documented and predicted protein structures in ePlant are respectively from PDB and Dr. Lawrence Kelley at Imperial College, London, in the UK, computed using his Phyre algorithm [updated 19 November 2009]",
		"Poplar annotations and lookup from Chung-Jui Tsai, University of Georgia, AffyAnnotationV3_102707mtu.csv [updated October 2007]",
	];

	return (
		<div className="about">
			<p>
				The BAR is funded in part by Centre for the Analysis of Genome Evolution and Function, grants from the
				Canada Foundation for Innovation to NJP, and from Genome Canada to the Arabidopsis Research Group at the
				Department of Cell and Systems Biology, University of Toronto.
				<br />
				<br />
				The BAR’s Scientific Advisory Board was constituted in the summer of 2011 and consists of the following
				individuals, who meet virtually on an annual basis or as needed.
				<br />
				<br />
				David Guttman (CAGEF/University of Toronto)
				<br />
				Juergen Ehlting (University of Victoria)
				<br />
				Sharon Regan (Queen&apos;s University)
				<br />
				Lincoln Stein (Ontario Institute for Cancer Research/Reactome)
				<br />
				Joseph Colasanti (University of Guelph)
				<br />
				Tanya Berardini (Phoenix Bioinformatics)
				<br />
				Doreen Ware (Cold Spring Harbor Laboratory)
				<br />
				<br />
				We are available for contract work and collaborations. We also have positions available for outstanding
				graduate students.
				<br />
				<br />
				<b>Open Data Policy:</b> The data on the BAR - including eFP Browser and ePlant outputs - are available
				for use under a <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY 4.0</a> licence. Please
				cite the appropriate data sets and tools when using BAR data in a publication. Thanks!
				<br />
				<br />
				For more information, please contact:{" "}
				<a href="mailto:nicholas.provart@utoronto.ca" title="email: nicholas.provart@utoronto.ca">
					nicholas.provart@utoronto.ca
				</a>
				. If you are interested in using the legacy version of the BAR, you can{" "}
				<a
					href="https://bar.utoronto.ca/welcome.htm"
					title="Legacy version of the BAR: http://bar.utoronto.ca/welcome.htm"
				>
					here
				</a>
				.
			</p>
			<hr />
			Additional BAR resources:
			<ul>
				{Object.keys(barResources).map((key, index) => {
					const resource = barResources[key];
					return (
						// eslint-disable-next-line react/no-array-index-key
						<li key={index}>
							<a
								href={resource.link}
								target={resource.newTab ? "_blank" : ""}
								rel={resource.newTab ? "noopener noreferrer" : ""}
							>
								{key}
							</a>
						</li>
					);
				})}
			</ul>
			<hr />
			<div>
				<i>BAR Version 23-09</i>
				<ul>
					{versionData.map((version, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<li key={index}>{version}</li>
					))}
				</ul>
			</div>
		</div>
	);
}

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./scripts/App";
import { register } from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();

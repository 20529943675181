import "./feedback.css";

/**
 * Feedback component
 * @returns {JSX.Element} Feedback component
 */
export default function Feedback() {
	// Returns a Wufoo component
	return (
		<iframe
			allowtransparency="true"
			className="feedback-wufoo-iframe"
			id="feedback-wufoo"
			sandbox="allow-forms allow-scripts allow-same-origin"
			src="https://jamiewaese.wufoo.com/embed/shvmgyz181mhf1/"
			title="Embedded Wufoo Form"
		>
			{" "}
			<a href="https://jamiewaese.wufoo.com/forms/shvmgyz181mhf1/">Fill out my Wufoo form!</a>{" "}
		</iframe>
	);
}

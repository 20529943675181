import { Skeleton } from "@mui/material";

/**
 * Skeleton placeholders for the BAR app
 * @param {String} props Which component you want the skeleton for
 * @returns {JSX.Element} Skeleton placeholder for each component
 */
export default function BARSkeleton(props) {
	const { type } = props;

	// Each skeleton component has its own type
	if (type === "apps") {
		// These are the BAR apps
		return (
			<>
				<Skeleton variant="h1" />
				<Skeleton variant="rect" width="100%" height="1.3em" />
				<Skeleton variant="h2" />
				<Skeleton variant="rect" width="100%" height="200px" />
			</>
		);
	}
	if (type === "carousel") {
		// Carousel
		return <Skeleton variant="rect" width="100%" height="250px" />;
	}
	if (type === "navbar") {
		// Navbar
		return <Skeleton variant="rect" width="100%" height="50px" />;
	}
	if (type === "marketing") {
		// Marketing
		return <Skeleton variant="rect" width="100%" height="300px" />;
	}
	if (type === "maintenance") {
		// The BAR is offline or going soon.
		return <Skeleton variant="rect" width="100%" height="25px" />;
	}
	if (type === "news") {
		// News
		return <Skeleton variant="rect" width="100%" height="650px" />;
	}

	// Default is nothing
	return null;
}

import { Button, Paper } from "@mui/material";

/**
 * Create carousel items
 * @param {Object} props
 * @returns {JSX.Element} Carousel items
 */
export default function CarouselItem(props) {
	const { image, customClass, title, caption, link, linkText, newTab } = props;

	/** Custom carousel CSS style */
	const customStyle = {
		backgroundImage: `url(${image})`,
		backgroundPosition: "initial",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		height: "250px",
		width: "100%",
	};

	/** Carousel classes */
	let classes = "carousel-item-text";
	if (customClass) {
		classes += ` ${customClass}`;
	}

	return (
		<Paper style={customStyle} className="carousel-item">
			<div className={classes}>
				<h1 className="">{title}</h1>
				<p>{caption}</p>
				{link ? (
					<Button
						className="app-button app-button-white carousel-button"
						href={link}
						rel={newTab ? "noopener noreferrer" : ""}
						target={newTab ? "_blank" : ""}
					>
						{linkText || "Go"}
					</Button>
				) : null}
			</div>
		</Paper>
	);
}

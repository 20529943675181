import * as Realm from "realm-web";

export default async function loginAnonymous() {
	const app = new Realm.App({ id: process.env.REACT_APP_ID });

	// Create an anonymous credential
	const credentials = Realm.Credentials.anonymous();

	try {
		// Authenticate the user
		// eslint-disable-next-line no-unused-vars
		const user = await app.logIn(credentials);

		const mongodb = app.currentUser.mongoClient(process.env.REACT_APP_CLIENT);

		return mongodb.db(process.env.REACT_APP_DB);
	} catch (err) {
		console.error("Failed to log in", err);
		return null;
	}
}

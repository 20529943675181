import Grid from "@mui/material/Grid";
import React, { Suspense, lazy } from "react";
import "./App.css";
import Apps from "./components/apps/Apps";
import Carousel from "./components/carousel/Carousel";
// import Maintenance from "./components/maintenance/Maintenance";
import Marketing from "./components/marketing/Marketing";
import NavBar from "./components/nav-bar/NavBar";
import News from "./components/news/News";

const BackToTop = lazy(() => import("./components/back-to-top/BackToTop"));
const CookieAndPrivacy = lazy(() => import("./components/cookies/PrivacyAndPolicy"));
const Sponsors = lazy(() => import("./components/sponsors/Sponsors"));

/**
 * App
 * @returns {JSX.Element} App
 */
export default function App() {
	return (
		<div className="App">
			<NavBar />
			<Carousel />
			<Grid container direction="row" justifyContent="space-around" alignItems="center">
				<Grid item xs={12} md={9}>
					{/* The following are commented out. If the BAR goes under maintenance, update .components/maintenance/maintenance.jsx and uncomment */}
					{/* <Maintenance />
					<hr /> */}

					{/* Add <hr /> in between components to add visual indicator */}
					<Marketing />
					<hr />
					<News />
					<hr />
					<Apps />
					<hr />
					<Suspense fallback={null}>
						<Sponsors />
					</Suspense>
				</Grid>
			</Grid>
			<Suspense fallback={null}>
				<CookieAndPrivacy />
				<BackToTop />
			</Suspense>
		</div>
	);
}

/** All retrieved images' data */
const images = {};

/**
 * Return image thumbnails
 * @param {String} which Which image you want returned
 * @returns {String} Directory path to image
 */
export default async function returnImages(which) {
	/** Image itself */
	let image;

	/** Supported image formats */
	const imageFormats = ["webp", "png", "jpg", "gif", "jpeg", "svg", "bmp", "ico", "tiff", "tif"];

	if (!images[which]) {
		// eslint-disable-next-line guard-for-in
		for (const i in imageFormats) {
			// Only proceed if the image does not exist
			if (!image && imageFormats[i]) {
				// Try to find image, if not, move to next format
				try {
					// eslint-disable-next-line no-await-in-loop
					image = await import(`../../../images/apps/${which}.${imageFormats[i]}`);
					images[which] = image.default;

					break;
				} catch (error) {
					continue;
				}
			}

			if (image) {
				// If image found, stop looping
				break;
			}
		}
	}

	// If image exists, then return it
	if (images[which]) {
		return images[which].toString();
	}

	return undefined;
}

/* eslint-disable no-param-reassign */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-loop-func */
import { Autocomplete, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Component } from "react";
import barToolOptions from "../../../../../data/bar_tool_options.json";
import cleanQuery from "../../../../helper/helper";
import loginAnonymous from "../../../../mongo/connection";
import "./search-bar.css";

/** Display the search bar */
export default class SearchBar extends Component {
	constructor() {
		super();
		// Declaration
		this.queryID = "searchQuery";

		// Functionality
		/** What species is being searched for */
		this.species = undefined;
		/** Regex for the species alias that was matched */
		this.speciesRegex = undefined;
		this.autofillSpecies = {};
		this.query = "";
		this.querySequence = "";
		this.barSpeciesOptions = {};
		this.sequenceQuery = false;
		this.sequenceType = undefined;
		/** Redirect data for all BAR tools */
		this.barToolsRedirect = {};
		/** Switch to determine if a search has been started or not */
		this.startedSearch = false;

		// Cache
		/** All auto-filter options */
		this.allFilterOptions = [];
		/** When an autofill option is selected, this is where it is stored */
		this.autofillCache = undefined;
		/** A cached object containing all searched terms */
		this.searchCache = {};
		/** All searched queries */
		this.searchQueries = [];

		this.state = {
			/** All autofill dropdown options */
			autofillOptions: [],
		};

		// Placeholder
		this.url = "";
	}

	componentDidMount() {
		// Init
		this.retrieveBARToolsOptions();
	}

	/**
	 * Handle key press actions
	 */
	handleKeyPress = (event) => {
		// Update query
		this.query = event.target.value.trim();

		if (this.query?.length >= 0 && event?.key) {
			if (event.key === "Enter") {
				// If started search and some query was inputted, then begin search
				this.startSearch();
			} else if (event.key !== "Shift") {
				// Fetch and display autofill data
				this.fetchAutofill();
			}
		}
	};

	/**
	 * Retrieve gene locus from gene annotation API based on keyword matches
	 */
	async searchKeyword(query) {
		const url = `https://bar.utoronto.ca/api/gene_annotation/${query}`;
		const methods = { mode: "cors" };
		await fetch(url, methods)
			.then(async (response) => {
				if (response.status === 200) {
					await response.text().then(async (data) => {
						let responseData;

						if (data.length > 0) {
							responseData = JSON.parse(data);
						} else {
							responseData = {};
						}

						if (responseData.status === "success") {
							let newAutofillData = {};
							responseData.result.forEach((element) => {
								let trimmedString = `${element.gene_annotation.replace(/^(.{50}[^\s]*).*/, "$1")}`;
								if (trimmedString.length < element.gene_annotation.length) {
									trimmedString += "...";
								}

								newAutofillData = {
									type: "Gene",
									name: `${element.gene.split(".")[0]}: ${trimmedString}`,
								};

								this.allFilterOptions.push(newAutofillData);
							});

							this.allFilterOptions = [
								...new Set(this.allFilterOptions.map((item) => JSON.stringify(item))),
							].map((item) => JSON.parse(item));
							this.allFilterOptions = this.allFilterCleaner(this.allFilterOptions);
							this.setState({
								autofillOptions: this.allFilterOptions,
							});
						}
					});
				}
			})
			.catch(async (err) => {
				console.error(err);
			});
	}

	/**
	 * Retrieve proteomic numbers and GO term matches from Uniprot
	 */
	async searchUniprot(query) {
		let url = `https://www.uniprot.org/uniprot/?limit=10&format=tab&columns=genes(OLN),go,proteome&query=goa(${query})+OR+proteome:${query}`;

		// set constraints on organisms
		const specieOptions = Object.keys(this.barSpeciesOptions)
			.map((specie) => `"${specie.replace("_", "%20")}"`)
			.join("+OR+");
		if (specieOptions) {
			url += `+(organism:${specieOptions})`;
		}

		const methods = { mode: "cors" };
		await fetch(url, methods)
			.then(async (response) => {
				if (response.status === 200) {
					await response.text().then(async (data) => {
						/** Parsed returned data */
						const page = new DOMParser().parseFromString(data, "text/html");
						const lines = page.body.textContent.replace(/\n$/, "").split(/\r?\n/);

						let locus;
						let goTerm;
						let proteome;
						const queryMatch = new RegExp(query, "i");
						let newAutofillData;
						let goList;
						for (let i = 1; i < lines.length; i + 1) {
							newAutofillData = { type: null, name: null };
							[locus, goTerm, proteome] = lines[i].split("\t");
							// find matches that has a locus
							if (locus) {
								if (proteome.match(queryMatch)) {
									newAutofillData.type = "Proteome ID";
									newAutofillData.name = `${locus}, ${proteome}`;
								} else {
									goList = goTerm.split(";");
									// iterate over list of GO Terms and return the first one that contains query
									for (const go of goList) {
										if (go.match(queryMatch)) {
											newAutofillData.type = "GO Term";
											newAutofillData.name = `${locus}: ${go}`;
											break;
										}
									}
								}
								if (newAutofillData.type) {
									this.allFilterOptions.push(newAutofillData);
									this.allFilterOptions = [
										...new Set(this.allFilterOptions.map((item) => JSON.stringify(item))),
									].map((item) => JSON.parse(item));
									this.allFilterOptions = this.allFilterCleaner(this.allFilterOptions);
									this.setState({
										autofillOptions: this.allFilterOptions,
									});
								}
							}
						}
					});
				}
			})
			.catch(async (err) => {
				console.error(err);
			});
	}

	/**
	 * Retrieve a list of available species that can be searched through for BAR tools
	 */
	retrieveBARToolsOptions() {
		if (Object.keys(this.barSpeciesOptions).length === 0 && barToolOptions) {
			// First sort species options
			if (barToolOptions?.species) {
				for (const [key, value] of Object.entries(barToolOptions.species)) {
					if (!this.barSpeciesOptions[key] && value?.alias) {
						/** All aliases of the species name */
						const speciesAliases = value.alias;

						/** All alternative ways to write the species name */
						const speciesVariation = [];

						for (const i in speciesAliases) {
							if (speciesAliases[i]) {
								const aliasWord = speciesAliases[i].toLowerCase().trim();

								// Alias as is
								speciesVariation.push(aliasWord);

								// No spaces in case of lazy searches
								speciesVariation.push(aliasWord.replace(/ /gi, ""));
								speciesVariation.push(aliasWord.replace(/ /gi, "-"));

								// The first word of each species
								speciesVariation.push(aliasWord.split(" ")?.[0]);

								// Short hand species alias
								if (aliasWord.split(" ").length === 2) {
									speciesVariation.push(`${aliasWord.split(" ")[0][0]}. ${aliasWord.split(" ")[1]}`);
								}
							}
						}

						this.barSpeciesOptions[key] = [...new Set(speciesVariation)];
					}
				}
			}

			// Create list of autofill/auto-correct BAR tool
			if (barToolOptions?.tools) {
				/** BAR Tool name and its variations */
				let barToolNames = [];

				// Go through each BAR tool available
				for (const [key, value] of Object.entries(barToolOptions.tools)) {
					barToolNames.push(`${key.split("_").join(" ").trim()}`);

					// eslint-disable-next-line no-unused-vars
					for (const [innerKey, innerValue] of Object.entries(value)) {
						if (innerValue?.name) {
							/** The human-readable BAR tool name */
							const toolName = innerValue.name.trim();
							/** A list of alternative names for the BAR tool */
							let alternativeNames = [];

							// Name as is
							alternativeNames.push(toolName.trim());

							/** What species is listed within the tool */
							const toolSpecies = this.determineSpecies(toolName.trim(), true);
							if (toolSpecies) {
								/** All aliases for the species name */
								const speciesAlias = barToolOptions.species[toolSpecies].alias;

								/** A list of species words to be replaced */
								let speciesWordReplacement = [toolSpecies.trim().split("_").join(" ")];

								// Go through each species alias word and find the first word (common name)
								for (const i in speciesAlias) {
									if (speciesAlias[i]) {
										speciesWordReplacement.push(speciesAlias[i].split(" ")?.[0]);
									}
								}

								// Make sure only unique words
								speciesWordReplacement = [...new Set(speciesWordReplacement)];

								/** Alternative species names to replace */
								const speciesReplacementNames = [...alternativeNames];

								for (const i in speciesReplacementNames) {
									if (speciesReplacementNames[i]) {
										/** Word to replace */
										let replaceWord;
										/** RegExp form of the word to replace */
										let replaceRegex;
										/** If a word to replace was found */
										let includes = false;

										for (const y in speciesWordReplacement) {
											// Does the alternative name contain a species word/name to replace?
											if (
												!includes &&
												speciesReplacementNames[i]
													.toLowerCase()
													.includes(speciesWordReplacement[y].toLowerCase())
											) {
												replaceWord = speciesWordReplacement[y];
												replaceRegex = new RegExp(`${replaceWord}`, "i");
												includes = true;
											}
										}

										// If something to replace, replace it
										if (includes) {
											for (const y in speciesWordReplacement) {
												if (speciesWordReplacement[y]) {
													alternativeNames.push(
														speciesReplacementNames[i].replace(
															replaceRegex,
															speciesWordReplacement[y],
														),
													);
												}
											}
										}
									}
								}
							}

							// Short-hand form
							/** Replace special characters with spaces */
							const noSpecialCharacters = toolName
								.replace(/.0/gi, "")
								.replace(/[^a-zA-Z0-9 ]/gi, " ")
								.trim();
							if (noSpecialCharacters.split(" ").length > 2) {
								/** The short-hand form name */
								let shortHand = "";

								for (const i in noSpecialCharacters.split(" ")) {
									if (noSpecialCharacters.split(" ")[i]?.[0]) {
										// Grab first letters as that is the typically/common short-hand format
										shortHand += noSpecialCharacters.split(" ")[i][0];
									}
								}

								if (shortHand.toLowerCase().trim() !== "env") {
									// env is not clear what tool that is so remove that
									alternativeNames.push(shortHand);
								}
							}

							// Only unique names
							alternativeNames = [...new Set(alternativeNames)];

							// Make words singular
							for (const i in alternativeNames) {
								if (alternativeNames[i]) {
									const wordSplit = alternativeNames[i].split(" ");

									for (const x in wordSplit) {
										if (!this.determineSpecies(wordSplit[x], true)) {
											const lowercaseWord = wordSplit[x].toLowerCase().trim();

											if (lowercaseWord[lowercaseWord.length - 1] === "s") {
												const singularWords = [...new Set(wordSplit)];

												singularWords[x] = singularWords[x].substring(
													0,
													singularWords[x].length - 1,
												);
												alternativeNames.push(singularWords.join(" "));
											}
										}
									}
								}
							}

							// Only unique names
							alternativeNames = [...new Set(alternativeNames)];

							// Create readable version
							for (const a in alternativeNames) {
								if (alternativeNames[a]) {
									barToolNames.push(`${alternativeNames[a]}`);

									// Redirect data to be used on search
									if (!this.barToolsRedirect[alternativeNames[a]]) {
										this.barToolsRedirect[alternativeNames[a]] = innerValue;
										this.barToolsRedirect[alternativeNames[a]].species = innerKey;
									}

									if (!this.barToolsRedirect[key.split("_").join(" ").trim()]) {
										this.barToolsRedirect[key.split("_").join(" ").trim()] = innerValue;
										this.barToolsRedirect[key.split("_").join(" ").trim()].species = innerKey;
									}
								}
							}
						}
					}
				}

				// Only unique names
				barToolNames = [...new Set(barToolNames)];
				/** The BAR tool in a readable object for Material-UI autocomplete */
				const barToolObject = [];
				for (const i in barToolNames) {
					if (barToolNames[i]) {
						/** The BAR tool data in "type" & "name" format */
						const barData = {
							type: "BAR Tool",
							name: barToolNames[i],
						};

						barToolObject.push(barData);
					}
				}

				// Add for Material-UI autocomplete
				this.allFilterOptions = [...new Set(barToolObject)];
				this.allFilterOptions = this.allFilterCleaner(this.allFilterOptions);
				this.setState({
					autofillOptions: this.allFilterOptions,
				});
			}
		}
	}

	/**
	 * Start search for the query on GAIA
	 */
	async startSearch() {
		if (!this.startedSearch) {
			// Search started, prevent multiple searches
			this.startedSearch = true;

			// Double check query exists
			if (this.autofillCache?.name?.trim().length > 0) {
				this.query = this.autofillCache.name.trim();
			} else if (document.getElementById(this.queryID)?.value?.trim().length > 0) {
				this.query = document.getElementById(this.queryID).value.trim();
			}

			/** If a type of search has been determined or not */
			let determineSearch = true;

			// If clicked on autofill dropdown, redirect to that data
			if (this.autofillCache?.name) {
				if (this.autofillCache?.type === "BAR Tool") {
					if (this.barToolsRedirect?.[this.autofillCache?.name]?.link) {
						this.redirectURL(this.barToolsRedirect?.[this.autofillCache?.name]?.link);

						determineSearch = false;
					}
				} else if (this.autofillCache?.type === "Gene" || this.autofillCache?.type === "GO Term") {
					const locus = this.autofillCache.name.split(":")[0];
					this.searchQuery(locus);
					determineSearch = false;
				} else if (this.autofillCache?.type === "Proteome ID") {
					const locus = this.autofillCache.name.split(",")[0];
					this.searchQuery(locus);
					determineSearch = false;
				} else if (this.autofillCache?.type) {
					this.searchQuery(this.autofillCache.name, this.autofillCache.type);

					determineSearch = false;
				}
			}

			// If not, then determine what type of search is needed
			if (determineSearch) {
				if ((!this.query || this.query.trim().length === 0) && this.autofillCache?.name) {
					this.query = this.autofillCache.name.trim();
				}

				this.determineSearchType();
			}
		}
	}

	/**
	 * Determines what type of page redirect the search bar will do
	 */
	async determineSearchType() {
		// Double-check for query to have no excess white-space
		this.query = this.query.trim();

		// Update species call
		this.determineSpecies();

		// First going to check if the query is a sequence or not:
		this.checkIfSequence();

		if (this.sequenceQuery) {
			/** Sequence string */
			const seq = this.querySequence || this.query;

			// Set up cookie expiry time - say 1 month.
			// We don't want forever cookies.
			const expires = new Date(Date.now() + 2629800).toUTCString();

			// Set-up cookies
			document.cookie = `sequence=${seq}; expires=${expires}; path=/; SameSite=Lax;`;
			document.cookie = `sequenceType=${this.sequenceType}; expires=${expires};  path=/; SameSite=Lax;`;

			// Redirect
			this.redirectURL(`https://bar.utoronto.ca/blast`);

			return;
		}

		// Next, check if there is any exact BAR tool names in query
		if (this.barToolsRedirect) {
			/** Regex for the query being searched against */
			const queryRegex = new RegExp(`${this.query.replace(/[^A-Z0-9]+/gi, ".")}`, "i");

			// Go through all the BAR tools and try and find a matched
			for (const [key, value] of Object.entries(this.barToolsRedirect)) {
				// Match against the tool category or the tool name
				if (key.match(queryRegex) || value?.name?.match(queryRegex)) {
					/** Should proceed forward [true] or not [false, default] */
					let proceed = false;

					// Only proceed if matched species or no species matched
					if (
						(this.species &&
							value?.species?.match(new RegExp(`${this.species.replace(/[^A-Z0-9]+/gi, ".")}`, "i"))) ||
						!this.species
					) {
						proceed = true;
					}

					if (proceed && value.link) {
						this.redirectURL(value.link);

						return;
					}
				}
			}
		}

		// If no exact BAR tools found, see if BAR tool searched with gene
		if (this.barToolsRedirect) {
			/** Query without the BAR tool in it */
			let queryWithoutTool;
			/** The matched BAR tool */
			let matchedTool;

			for (const [key, value] of Object.entries(this.barToolsRedirect)) {
				if (!matchedTool) {
					/** Should proceed forward [true] or not [false, default] */
					let proceed = false;

					// Only proceed if matched species or no species matched
					if (
						(this.species &&
							value?.species?.match(new RegExp(`${this.species.replace(/[^A-Z0-9]+/gi, ".")}`, "i"))) ||
						!this.species
					) {
						proceed = true;
					}

					if (proceed && value.link) {
						/** Regex list */
						const regexTest = [key, value.name];

						// Create tool category without species in it
						/** The species found in the tool category */
						// eslint-disable-next-line no-unused-vars
						const keySpecies = this.determineSpecies(key, true);
						/** Tool category and name without species in it */
						const keyWithoutSpecies = key.replace(this.speciesRegex, "").trim();
						if (keyWithoutSpecies) {
							regexTest.push(keyWithoutSpecies);
						}

						// Create tool name without species in it
						/** The species found in the tool name */
						// eslint-disable-next-line no-unused-vars
						const nameSpecies = this.determineSpecies(value.name, true);
						const nameWithoutSpecies = value.name.replace(this.speciesRegex, "").trim();
						if (nameWithoutSpecies) {
							regexTest.push(nameWithoutSpecies);
						}

						for (const i in regexTest) {
							if (regexTest[i]) {
								regexTest[i] = new RegExp(
									`(^| )${regexTest[i].replace(/[^A-Z0-9]+/gi, ".")}( |$)`,
									"i",
								);

								if (this.query.match(regexTest[i])) {
									matchedTool = value;

									queryWithoutTool = this.query.replace(regexTest[i], "").trim();

									break;
								}
							}
						}
					}
				}
			}

			// If a match was found
			if (matchedTool && queryWithoutTool) {
				if (matchedTool?.locusLink && matchedTool?.link) {
					await this.searchMatch(queryWithoutTool).then(async () => {
						if (this.searchCache?.[queryWithoutTool.toLowerCase()]) {
							/** Search data for query without the tool */
							const searchQueryWithoutToolData = this.searchCache[queryWithoutTool.toLowerCase()];

							/** If an exact alias was found */
							let exactMatch;
							/** If an alias begins with the query */
							let startWithMatch;
							/** If an alias contains the query */
							let containsMatch;

							/** Literally any locus */
							let anyMatch;

							for (const s in searchQueryWithoutToolData) {
								if (searchQueryWithoutToolData[s]) {
									// Find any exact matches?
									if (
										!exactMatch &&
										searchQueryWithoutToolData[s]?.locus &&
										searchQueryWithoutToolData[s]?.alias
									) {
										for (const a in searchQueryWithoutToolData[s].alias) {
											if (
												searchQueryWithoutToolData[s].alias[a].match(
													new RegExp(
														`^${queryWithoutTool.replace(/[^A-Z0-9]+/gi, ".")}$`,
														"i",
													),
												)
											) {
												exactMatch = searchQueryWithoutToolData[s].locus;
											}
										}
									}

									// Find any matches that start with the query?
									if (
										!startWithMatch &&
										searchQueryWithoutToolData[s]?.locus &&
										searchQueryWithoutToolData[s]?.alias
									) {
										for (const a in searchQueryWithoutToolData[s].alias) {
											if (
												searchQueryWithoutToolData[s].alias[a].match(
													new RegExp(
														`^${queryWithoutTool.replace(/[^A-Z0-9]+/gi, ".")}`,
														"i",
													),
												)
											) {
												startWithMatch = searchQueryWithoutToolData[s].locus;
											}
										}
									}

									// Find any matches that contain the query?
									if (
										!containsMatch &&
										searchQueryWithoutToolData[s]?.locus &&
										searchQueryWithoutToolData[s]?.alias
									) {
										for (const a in searchQueryWithoutToolData[s].alias) {
											if (
												searchQueryWithoutToolData[s].alias[a].match(
													new RegExp(`${queryWithoutTool.replace(/[^A-Z0-9]+/gi, ".")}`, "i"),
												)
											) {
												containsMatch = searchQueryWithoutToolData[s].locus;
											}
										}
									}

									// Find literally anything
									if (!anyMatch) {
										anyMatch = searchQueryWithoutToolData[s].locus;
									}
								}
							}

							/** What locus is going to be used */
							const useLocus = exactMatch || startWithMatch || containsMatch || anyMatch;

							if (useLocus) {
								const url = matchedTool.locusLink.split("{locus}").join(useLocus);

								this.redirectURL(url);
							} else {
								this.redirectURL(matchedTool.link);
							}
						} else if (matchedTool.link) {
							this.redirectURL(matchedTool.link);
						} else {
							this.searchQuery(this.query);
						}
					});
				} else if (matchedTool?.link) {
					this.redirectURL(matchedTool.link);
				}
			} else {
				this.searchQuery(this.query);
			}
		} else {
			this.searchQuery(this.query);
		}
	}

	/**
	 * Determine what species, if specified, is being searched for
	 * @param {String} query The query that may contain a species in it
	 * @param {Boolean} returnSpecies Whether you want the species to be return as a string [true] or not [false, default]
	 * @returns {String} foundSpecies - The species that was found (undefined for nothing found)
	 */
	determineSpecies(query = this.query, returnSpecies = false) {
		/** Species found within the string  */
		let foundSpecies;

		// eslint-disable-next-line no-param-reassign
		query = cleanQuery(query);

		if (query?.length > 0) {
			if (
				query?.toLowerCase().trim() === this.autofillSpecies?.queryUse?.toLowerCase().trim() &&
				this.autofillSpecies?.speciesName
			) {
				foundSpecies = this.autofillSpecies.speciesName;

				foundSpecies = foundSpecies.split(" ").join("_");
				// Capitalizing the first letter
				foundSpecies = foundSpecies.charAt(0).toUpperCase() + foundSpecies.substring(1);
			} else if (this.barSpeciesOptions) {
				/** An array of all species options */
				for (const [key, value] of Object.entries(this.barSpeciesOptions)) {
					/** An array of all species names and their related aliases */
					for (const i in value) {
						if (value[i]) {
							// Going through each species alias option and see if the query contains it
							const speciesRegex = new RegExp(`${value[i].replace(/[^A-Z0-9]+/gi, ".")}`, "i");
							if (query?.trim().toLowerCase().match(speciesRegex)) {
								foundSpecies = key;

								this.speciesRegex = speciesRegex;

								if (!returnSpecies) {
									// Clean query
									this.query = this.query.replace(speciesRegex, "").trim();
									this.query = cleanQuery(this.query);
								}
							}
						}
					}
				}
			}
		}

		if (returnSpecies) {
			return foundSpecies;
		}
		this.species = foundSpecies;

		return undefined;
	}

	/**
	 * Check if the query input is a sequence or not
	 */
	checkIfSequence() {
		this.sequenceQuery = false;
		this.sequenceType = undefined;

		// 18 as that is typically the shortest length for a primer
		if (this.query.length >= 11) {
			/** All matched sequence characters */
			let allMatched = "";

			/** Regex to match nucleotide sequences */
			const nucleotideRegex = /\b[atcg]+\b(?![,])/gi;
			if (!this.sequenceQuery && this.query.match(nucleotideRegex)) {
				for (const i in this.query.match(nucleotideRegex)) {
					if (this.query.match(nucleotideRegex)[i]) {
						allMatched = "";
						if (this.query.match(nucleotideRegex)[i].length >= 3) {
							allMatched += this.query.match(nucleotideRegex)[i];
						}
					}
				}

				if (allMatched.length >= 11) {
					this.sequenceQuery = true;
					this.sequenceType = "nucleotide";
					this.querySequence = allMatched;
				}
			}

			/** Regex to match protein sequences */
			const proteinRegex = /\b[acdefghiklmnpqrstvwy]+\b(?![,])/gi;
			if (!this.sequenceQuery && this.query.match(proteinRegex)) {
				allMatched = "";
				for (const i in this.query.match(proteinRegex)) {
					if (this.query.match(proteinRegex)[i].length >= 3) {
						allMatched += this.query.match(proteinRegex)[i];
					}
				}

				if (allMatched.length >= 11) {
					this.sequenceQuery = true;
					this.sequenceType = "protein";
					this.querySequence = allMatched;
				}
			}
		}
	}

	/**
	 * Redirects the page to the string inputted to this function
	 * @param {String} url The URL which the page will redirect too
	 */
	redirectURL(url) {
		// Add encoding
		url = encodeURI(url);

		window.location.href = url;
	}

	/**
	 * Generate a new URL for the search page and change to that page
	 * @param {String} queryID The ID of the query input
	 * @param {String} species The species of the search query (if exists)
	 */
	searchQuery(query = this.query, species = this.species) {
		// Remove any underscores from species name and replace with spaces
		this.determineSpecies();
		if (species) {
			species = species.trim().split("_").join(" ");
		}

		// Find existing URL
		this.url = window.location.origin;

		// Clean query
		if (this.species) {
			query = query.replace(this.speciesRegex, "").trim();
		}
		query = cleanQuery(query);

		this.url += `/gaia/search?query=${query}`;

		if (species && this.url.includes("query")) {
			this.url += ` ${species.trim()}`;
		}

		this.redirectURL(this.url.trim());
	}

	/**
	 * Retrieve and fetch data relating to the autofill function
	 * @param {String} query The ID of the query input
	 */
	async fetchAutofill(queryID = this.queryID) {
		this.sequenceQuery = false;
		/** Query being searched against */
		const query = document.getElementById(queryID).value.trim();

		this.searchMatch(query);
		this.searchKeyword(query);
		this.searchUniprot(query);
	}

	/**
	 * Find an exact match to the query
	 * @param {String} query The search query
	 * @param {Boolean} deeperSearch Expand search for more results [default, false]
	 * @param {Number} searchType The type of search to perform: 1 [default] = single exact match, 2 = exact matches with multiple options, 3 = matches that begin with the query, 4 = matches that contain the query
	 */
	async searchMatch(query = this.query, deeperSearch = false, searchType = 1) {
		// Proceed to search if not already searched for
		if (
			deeperSearch ||
			(!this.searchCache[this.query?.toLowerCase().trim()] && !this.searchQueries.includes(query))
		) {
			this.searchQueries.push(query);

			/** RegExp version of query */
			const regexQuery = new RegExp(
				`^${query
					.split("\\")
					.join(" ")
					.replace(/[^A-Z0-9]+/gi, ".")}$`,
				"i",
			);
			/** RegExp for begins with query */
			const regexBeginWith = new RegExp(
				`^${query
					.split("\\")
					.join(" ")
					.replace(/[^A-Z0-9]+/gi, ".")}`,
				"i",
			);
			/** RegExp for contains the query */
			const regexContains = new RegExp(
				`${query
					.split("\\")
					.join(" ")
					.replace(/[^A-Z0-9]+/gi, ".")}`,
				"i",
			);

			/** The MongoDB search query/string */
			const mongoQuery =
				// eslint-disable-next-line no-nested-ternary
				searchType === 1 || searchType === 2
					? {
							// eslint-disable-next-line no-useless-computed-key
							$or: [{ ["aliases"]: regexQuery }, { ["locus"]: regexQuery }, { ["geneid"]: regexQuery }],
						}
					: searchType === 3
						? {
								// eslint-disable-next-line no-useless-computed-key
								$or: [
									{ aliases: regexBeginWith },
									{ locus: regexBeginWith },
									{ geneid: regexBeginWith },
								],
							}
						: {
								// eslint-disable-next-line no-useless-computed-key
								$or: [{ aliases: regexContains }, { locus: regexContains }, { geneid: regexContains }],
							};

			/** Indicate to MongoDB what to return [1] and not return [0] */
			const projection = {
				projection: {
					aliases: 1,
					locus: 1,
					species: 1,
					geneid: 1,
					_id: 0,
				},
			};

			// Find one if not told to return the locus
			projection.limit = searchType === 1 ? 1 : 6;

			await loginAnonymous()
				.then((db) => db.collection("aliases").find(mongoQuery, projection))
				.then((docs) => {
					if (docs) {
						if (!this.searchCache[query?.toLowerCase().trim()]) {
							this.searchCache[query?.toLowerCase().trim()] = docs;
						} else {
							this.searchCache[query?.toLowerCase().trim()] = {
								...this.searchCache[query?.toLowerCase().trim()],
								...docs,
							};
						}

						/** New autofill data */
						const newAutofillData = [];
						for (const i in docs) {
							if (docs[i]?.species && docs[i]?.aliases) {
								for (const a in docs[i].aliases) {
									if (docs[i].aliases[a]) {
										/** The new data being added to Material-UI autocomplete */
										const newData = {
											type: docs[i].species.split("_").join(" ").trim(),
											name: docs[i].aliases[a].trim(),
										};
										newAutofillData.push(newData);
									}
								}
							}
						}

						this.allFilterOptions = [...new Set([...this.allFilterOptions, ...newAutofillData])];

						this.allFilterOptions = this.allFilterCleaner(this.allFilterOptions);

						this.setState({
							autofillOptions: this.allFilterOptions,
						});

						if (docs.length < 5 && Object.keys(this.searchCache[query?.toLowerCase().trim()])?.length < 5) {
							if (searchType < 4) {
								this.searchMatch(query, true, searchType + 1);
							}
						}
					}
				})
				.catch((err) => {
					console.error(err);
					this.searchCache[query?.toLowerCase().trim()] = {};
				});
		}
	}

	/**
	 * Clean the all filter options of duplicate entries
	 * @param {Array | Object} currentOptions
	 * @returns {Array} Array of unique options
	 */
	allFilterCleaner(currentOptions) {
		/** Unique options that will be returned */
		const uniqueOptions = [];
		/** Unique options as a string format to double check against */
		const uniqueOptionsList = [];

		// eslint-disable-next-line no-unused-vars
		for (const [, value] of Object.entries(currentOptions)) {
			if (value?.type && value?.name) {
				/** How string will be displayed to user */
				const nameDisplay = `${value.name} (${value.type})`;

				if (!uniqueOptionsList.includes(nameDisplay)) {
					uniqueOptions.push(value);
					uniqueOptionsList.push(nameDisplay);
				}
			}
		}

		return uniqueOptions;
	}

	render() {
		const { autofillOptions } = this.state;

		/** Placeholder query */
		const queryFill = "Search for a BAR tool, gene or gene related question, or with a sequence in FASTA format";
		/** The CSS class for the search bar container */
		const containerClass = "searchBar";
		/** The CSS class for the search bar input */
		const searchBarClass = ["inputFills"];

		/** The autofill/autocomplete data for Material-UI to display */
		const displayRender = autofillOptions;

		return (
			<div id="searchBar" className={containerClass}>
				<Autocomplete
					aria-label="Search by BAR tool, gene, or gene related question"
					className={searchBarClass.join(" ")}
					freeSolo
					getOptionLabel={(option) => option.name || option}
					id={this.queryID}
					onChange={(_event, newValue) => {
						this.autofillCache = newValue;
						this.startSearch();
					}}
					onKeyUp={this.handleKeyPress}
					options={displayRender}
					title="Search by BAR tool, gene, or gene related question"
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={queryFill}
							margin="normal"
							variant="outlined"
							aria-label="Search by BAR tool, gene, or gene related question"
						/>
					)}
					renderOption={(props, option, { inputValue }) => {
						const optionName = `${option.name} (${option.type})`;
						/** All matches */
						const matches = match(optionName, inputValue, {
							findAllOccurrences: true,
							insideWords: true,
						});
						/** Word parts that match */
						const parts = parse(optionName, matches);

						return (
							<li {...props} key={`${option.name}-${option.type}`}>
								<div>
									{parts.map((part, index) => (
										<span
											// eslint-disable-next-line react/no-array-index-key
											key={index}
											style={{
												fontWeight: part.highlight ? 700 : 400,
											}}
										>
											{part.text}
										</span>
									))}
								</div>
							</li>
						);
					}}
				/>
			</div>
		);
	}
}

/* eslint-disable no-await-in-loop */
import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Suspense, lazy, useEffect, useState } from "react";
import BARSkeleton from "../skeleton/Skeleton";
import "./apps.css";
import data from "./apps.json";
import returnImages from "./imageImporter";

const Button = lazy(() => import("@mui/material/Button"));
const Card = lazy(() => import("@mui/material/Card"));
const CardActionArea = lazy(() => import("@mui/material/CardActionArea"));
const CardHeader = lazy(() => import("@mui/material/CardHeader"));
const CardContent = lazy(() => import("@mui/material/CardContent"));

/**
 * Display BAR apps onto the page
 * @returns The BAR apps
 */
export default function Apps() {
	/** JSX of BAR apps */
	const [displayApps, setDisplayApps] = useState([]);
	/** Whether dialog should be open [true] or not [false, default] */
	const [open, setOpen] = useState(false);
	/** The title of the dialog */
	const [modalTitle, setModalTitle] = useState("");
	/** What content the dialog will hold */
	const [modalContent, setModalContent] = useState("");
	/** Close the dialog */
	const handleClose = () => setOpen(false);

	/**
	 * Opening the dialog
	 * @param {String} title The title of the dialog
	 * @param {String} content The content of the dialog
	 */
	function handleOpen(title, content) {
		setModalTitle(title);
		setModalContent(content);
		setOpen(true);
	}

	/**
	 * Create buttons for each BAR app
	 * @param {Object} item Button data
	 * @param {String} key BAR app name
	 * @returns The buttons for each BAR app
	 */
	function appButtons(item, key) {
		/** JSX for BAR app's button */
		const appButtonsJSX = [];

		if (item.info) {
			// Info button
			appButtonsJSX.push(
				<Button
					key={`app-button-${key}-${item.name}`}
					aria-label={`Learn more about ${item.name}`}
					className="app-button app-button-white"
					onClick={(e) => {
						e.preventDefault();
						handleOpen(item.name, item.info);
					}}
					title={`Learn more about ${item.name}`}
				>
					Info
				</Button>,
			);
		}

		if (item.link) {
			// Go to BAR app button
			appButtonsJSX.push(
				<Button
					key={`app-button-${key}-${item.link}`}
					aria-label={`Go to the ${item.name} app`}
					className="app-button app-button-go"
					href={item.link}
					title={`Go to ${item.name}'s tool`}
				>
					Go
				</Button>,
			);
		}

		if (item.pub) {
			// Publication button
			for (const i in item.pub) {
				if (item.pub[i]) {
					appButtonsJSX.push(
						<Button
							className="app-button app-button-white"
							key={`app-button-${key}-${item.pub[i]}`}
							href={item.pub[i]}
							target="_blank"
							rel="noopener noreferrer"
							title={`See ${item.name}'s publication`}
							aria-label={`See ${item.name}'s publication`}
						>
							Pub
						</Button>,
					);
				}
			}
		}

		return appButtonsJSX;
	}

	/**
	 * Create grid item for each BAR app
	 * @param {Object} items
	 * @returns Grid item for each BAR app
	 */
	async function createAppGridItems(items) {
		/** JSX of individual BAR apps for the grid */
		const appGridItems = [];

		for (const [key, item] of Object.entries(items)) {
			/** BAR app thumbnail */
			const appImage = await returnImages(item.name);

			appGridItems.push(
				<Grid item key={`app-grid-item-${key}`}>
					<Card className="app-card" key={`app-card-${key}`}>
						<CardActionArea href={item.link} key={`app-card-action-area-${key}`}>
							{/* BAR app name */}
							<CardHeader className="app-card-header" title={item.name} key={`app-card-header-${key}`} />

							{/* BAR thumbnail and buttons */}
							<CardContent className="app-card-content" key={`app-card-content-${key}`}>
								{appImage ? (
									<img
										loading="lazy"
										className="app-image"
										src={appImage}
										alt={item.name}
										key={`app-card-image-${item.name}`}
									/>
								) : null}
								{appButtons(item, key)}
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>,
			);
		}

		return appGridItems;
	}

	/**
	 * Create grid of BAR apps
	 * @param {Object | Array} gridData
	 * @returns The grid of BAR apps
	 */
	async function createAppGrid(gridData) {
		if (Array.isArray(gridData)) {
			// If array, create grid of apps
			return <Grid container>{await createAppGridItems(gridData)}</Grid>;
		}
		// If object, create grid of categories
		/** JSX of BAR apps for the grid */
		const appGrid = [];

		for (const [key, value] of Object.entries(gridData)) {
			appGrid.push(
				<>
					{/* App sub-category */}
					<h2 className="apps-category" key={`apps-category-${key}`}>
						{key}
					</h2>

					{/* App grid */}
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="stretch"
						spacing={1}
						key={`apps-grid-${key}`}
					>
						{await createAppGrid(value)}
					</Grid>
				</>,
			);
		}

		return appGrid;
	}

	/**
	 * Display the apps onto the page
	 */
	async function createDisplayApps() {
		/** JSX of BAR apps */
		const apps = [];

		for (const [key, value] of Object.entries(data)) {
			/** Container for each app */
			apps.push(
				<div id={value?.id} className={`${value?.id}`} key={`${key}-${value?.id}`}>
					{
						// Display hr if not the first app category
						key !== Object.keys(data)[0] ? <hr key={`${key}-${value?.id}-hr`} /> : null
					}

					{/* Header */}
					<h1 className="apps-header" key={`${key}-${value?.id}-header`}>
						{key}
					</h1>

					{/* App category description */}
					<p className="apps-description" key={`${key}-${value?.id}-description`}>
						{value?.description}
					</p>

					{/* App grid */}
					{await createAppGrid(value?.apps)}
				</div>,
			);
		}

		setDisplayApps(apps);
	}

	useEffect(() => {
		if (displayApps.length === 0) {
			createDisplayApps();
		}
	});

	return (
		<Suspense fallback={<BARSkeleton type="apps" />}>
			<div className="bar-apps" key="bar-apps">
				{displayApps}

				{/* Dialog */}
				<Dialog open={open} onClose={handleClose} maxWidth="sm">
					<DialogTitle>
						<Grid container justifyContent="space-around" alignItems="center">
							<Grid item>
								<Typography variant="h4">{modalTitle}</Typography>
							</Grid>
							<Grid item>
								<IconButton aria-label="close" className="close-button" onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent dividers>
						{modalContent.split("\n").map((str) => (
							<p key={`modal-content-${str.substring(0, 10)}`}>{str}</p>
						))}
					</DialogContent>
				</Dialog>
			</div>
		</Suspense>
	);
}

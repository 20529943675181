/**
 * Cleans the query of any trailing special characters
 * @param {String} queryUse The query being cleaned of any trailing special characters
 * @returns {String} toReturn - The cleaned query
 */
export default function cleanQuery(queryUse = "") {
	/** Cleaned query to return */
	let toReturn = queryUse.trim();

	// Regex taken from: https://stackoverflow.com/questions/58469258/regex-remove-all-leading-and-trailing-special-characters
	/** The regex that will be checking the query */
	// eslint-disable-next-line no-useless-escape
	const regexChecker = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/i;

	/** Checking the query against regex */
	if (toReturn[0]?.match(regexChecker)) {
		toReturn = toReturn.substring(1, toReturn.length);

		toReturn = cleanQuery(toReturn);
	} else if (toReturn[toReturn.length - 1]?.match(regexChecker)) {
		toReturn = toReturn.substring(0, toReturn.length - 1);

		toReturn = cleanQuery(toReturn);
	}

	return toReturn.trim();
}

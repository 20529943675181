/* eslint-disable react/no-array-index-key */

/* eslint-disable react/no-unstable-nested-components */
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import MUICarousel from "react-material-ui-carousel";
import anniversary from "../../../images/carousel/50YearsArabidopsis.webp";
import gcbr from "../../../images/carousel/bar_GCBR.webp";
import birthday from "../../../images/carousel/bar_birthday.webp";
import welcome from "../../../images/carousel/slide1.webp";
import plants from "../../../images/carousel/slide2.webp";
import dataViz from "../../../images/carousel/slide3.webp";
import CarouselItem from "./CarouselItems";
import "./carousel.css";

// TODO: react-material-ui-carousel needs to be replaced
// @AlexJSully - react-material-ui-carousel is a mess and not compatible with React 18 AND not mobile friendly. Recommend try using MUI slide instead

/**
 * Carousel component
 * @returns {JSX.Element} Carousel component
 */
export default function Carousel() {
	/**
	 * What items should appear in carousel, order of first to last
	 * Needs the following elements:
	 * - title: {String} - Title of carousel item
	 * - caption: {String} - Description or subtext of carousel item
	 * - image: {Path} - Directory or path to image
	 * - imageAlt: {String} - Alt text for image
	 *
	 * Optional elements:
	 * - link: {String} - If carousel item links out, then link should be provided
	 * - linkText: {String} - Text to display on link button
	 * - newTab: {Boolean} - If true, link will open in new tab
	 * - customClass: {String} - Custom class to apply to carousel item
	 */
	const carouselItems = [
		{
			title: "Welcome to the BAR!",
			caption: "Web-based tools for visualizing functional genomics and other data.",
			image: welcome,
			imageAlt: "Welcome to the BAR! Web-based tools for visualizing functional genomics and other data.",
		},
		{
			title: "Plants & Animals",
			caption:
				"Most of our tools are designed with the plant researcher in mind \n but we also create tools for the wider research community.",
			image: plants,
			imageAlt:
				"Most of our tools are designed with the plant researcher in mind but we also create tools for the wider research community.",
		},
		{
			title: "Interactive Data Visualization",
			caption:
				"We're passionate about data visualization. \n Feel free to explore and use our tools. If you find them helpful, cite us!",
			image: dataViz,
			imageAlt:
				"We're passionate about data visualization. Feel free to explore and use our tools. If you find them helpful, cite us!",
		},
		{
			title: "The BAR has been online since 2003",
			caption: "The original BAR server was called the BBC ;-)",
			link: "https://bar.utoronto.ca/publications/",
			linkText: "BAR Publications",
			newTab: true,
			image: birthday,
			imageAlt:
				"The BAR celebrated its  15th birthday in 2020! The original BAR paper by Toufighi et al. was published in 2005 in The Plant Journal.",
		},
		{
			title: "BAR papers have been cited 11,011 times",
			caption: "Explore 50 years of Arabidopsis research with our citation network explorer.",
			link: "//bar.utoronto.ca/50YearsOfArabidopsis",
			linkText: "Try it now!",
			image: anniversary,
			imageAlt: "BAR papers have been cited 10,497 times.",
			customClass: "carousel-item-text-dark",
		},
		{
			title: "The BAR is a GCBR!",
			caption: "GCBRs are designated by the GBC",
			link: "https://globalbiodata.org/what-we-do/global-core-biodata-resources/",
			linkText: "More info",
			newTab: true,
			image: gcbr,
			imageAlt: "The BAR is part of GCBR.",
			customClass: "carousel-item-text-dark",
		},
	];

	return (
		<MUICarousel
			NextIcon={<ArrowForwardIosIcon color="white" />}
			PrevIcon={<ArrowBackIosIcon color="white" />}
			animation="slide"
			autoplay
			className="bar-carousel"
			cycleNavigation
			fullHeightHover
			indicators={false}
			interval={5000}
			navButtonsAlwaysInvisible={false}
			navButtonsAlwaysVisible
			swipe
		>
			{carouselItems.map((item, index) => (
				<CarouselItem key={index} {...item} />
			))}
		</MUICarousel>
	);
}
